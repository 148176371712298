@import "~component-library/dist/styles/constants.scss";

.wrapper {
  display: flex;
}

.mainContent {
  @media screen and (min-width: 1600px) {
    border-right: $gray06 solid 1px;
  }
}

.pill {
  padding: 0.1rem 0.8rem;
  border-radius: 2.5rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  text-align:  center;
  margin: 0;
  color: $white;
  
  &.active {
    background-color: #7DDB2D;
  }
  &.precon {
    background-color: $yellowOrange;
  }
  &.bidding, &.bid {
    background-color: $yellow;
  }
}

