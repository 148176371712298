@import "~component-library/dist/styles/constants.scss";

.form {
  @media(min-width: 992px) {
    max-width: 70rem;
    padding: 0 3rem 0;
    max-height: calc(100vh - 7.5rem);
    overflow: auto;
    margin-right: 2.5rem;
  }
}

.operationType {
  display: inline-block;
  border: 2px solid $gray05;
  border-radius: 2rem;
  padding: 0.6rem 1.5rem;
  margin: 0 1.5rem 0 0;
  color: $gray04;
  background-color: $white;
}

.typeActive {
  border: 2px solid $boomerangBlue;
  color: $white;
  background-color: $boomerangBlue;
}

.sidebar {
  margin: 0 2rem;

  @media(min-width: 992px) {
    max-width: 26.5rem;
  }
}

.desktopInfo {
  display: none;

  @media (min-width: 992px) {
    display: block;
  }
}

.mobileInfo {
  margin-top: 1.5rem;

  @media(min-width: 992px) {
    display: none;
  }
}

.groupCheckbox {
  display: inline-block;
  margin: 1rem;
}