@import "~component-library/dist/styles/constants.scss";

.statusPill {
  border-radius: 1.2rem;
  line-height: 1.6rem;
  color: #fff;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 600;
  min-width: 5.3rem;
  text-align: center;
}

.fieldLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.75);
  z-index: 99;
  left: 0;
}

.controls {
  .link {
    padding: 0 1.1rem;
    border-right: 1px solid $gray06;
    line-height: 1.8rem;
    &:last-child {
      border-right: 0 none;
      padding-right: 0;
    }
  }
}

.dateLabel {
  font-weight: 600;
  white-space: nowrap;
  font-size: 1.6rem;
  margin-right: 1.2rem;
  margin-bottom: 0;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.75);
  z-index: 99;
  left: 0;
}

.contactSection {
  display: flex;
  flex-direction: column;
  border-top: 1px solid $gray06;

  @media (min-width: 800px) {
    flex-direction: row;
  }
}

.section {
  border-top: 1px solid $gray06;
}

.contacts {
  padding: 1.8rem 0;
  @media (min-width: 450px) {
    display: flex;
  }
  @media (min-width: 800px) {
    width: 60%;
  }
}

.fileSection {
  padding-top: 1.8rem;
  @media (min-width: 800px) {
    width: 40%;
    border-left: 1px solid $gray06;
    padding-right: 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.file {
  min-width: 22.4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.fileField {
  border: 1px solid $gray06;
  width: 100%;
  border-radius: 0.4rem;
  height: 4.8rem;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.fileLink {
  padding-left: 0.5rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
  svg {
    margin-right: 1.6rem;
  }
}

.iconButton {
  border: 0 none;
  padding: 1.1rem;
  background-color: transparent;
}

.contact {
  width: 100%;
  margin-bottom: 1rem;
  @media (min-width: 450px) {
    width: 50%;
    margin-bottom: 0;
    margin-right: 1.4rem;
    max-width: 19.3rem;
    &:last-child {
      margin-right: 0;
    }
  }
}

.secondary {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;
  @media (min-width: 40rem) {
    flex-direction: row;
  }
}

.nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 40rem) {
    flex-direction: row;
  }
}

.label {
  font-weight: 600;
  margin-bottom: 0;
  font-size: 1.6rem;
}

.scope {
  padding-top: 2rem;
  width: 100%;
}

.scopeDisplay {
  margin-bottom: 3.2rem;
  margin-top: 1.6rem;
}

.sumField {
  *[class*="form-group"] {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1.1rem;

    label {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 1.6rem;
      margin-right: 0.5rem;
    }

    input {
      font-weight: 600;
      font-size: 1.6rem;
      text-align: right;
      width: 12rem;
    }
  }
}

.sumDisplay {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 1.5rem 0;
}

.sumReason {
  padding-top: 1.6rem;
  border-top: 1px solid $gray06;
}

.sumEdit {
  padding-top: 1.5rem;
  padding-bottom: 1.7rem;
}

.sumReasonField {

  *[class*="form-group"] {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  label {
    margin-bottom: 0.7rem;
    font-weight: 600;
    font-size: 1rem;
  }
}

.sumButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    margin-top: 0;
    margin-left: 1rem;
  }
}

.signature {
  border: 1px solid $gray05;
  background-color: $gray09;
  margin-top: 4.8rem;
  margin-bottom: 4rem;
  text-align: center;
  padding: 2rem 0;
}

.versionHistory {
  margin-top: 3rem;
}

.quoteSection {
  margin-top: 2rem;
}

.quoteId {
  width: 10rem;
}
.quoteAmount {
  text-align: right;
}

.quoteSupplier {
  font-weight: 600;
}

.history {
  margin-top: 0.9rem;
  thead th {
    border: 0 none;
    color: $gray03;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 400;
    padding: 0.4rem 0.8rem;
  }
  td {
    border: 0 none;
    padding: 0.4rem 0.8rem;
  }
  tr {
    border: 0 none;
  }
  tr:nth-of-type(odd) {
    td {
      background-color: $gray08;
      &:first-child {
        border-radius: 0.4rem 0 0 0.4rem;
      }
      &:last-child {
        border-radius: 0 0.4rem 0.4rem 0;
      }
    }
  }
  tr:nth-of-type(even) {
    td {
      background-color: #fff;
    }
  }
}

.tableFileLink {
  text-align: right;
}

.datePicker {
  button {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    display: flex;
    align-items: center;
  }
  span {
    font-size: 1.6rem;
  }

  svg {
    margin-left: 1.2rem !important;
  }
}

.iframe {
  width: 100%;
  align-self: stretch;
  border: 0 none;
}

.iframeModal{
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 0 none;
  z-index: 100;
  position: relative;
}

.full {
  width: 95%;
  max-width: 95%;
  margin: 2.5vh 2.5%;

  *[class*="modal-content"] {
    height: 95vh;
  }
}