@import "~component-library/dist/styles/constants.scss";

.form-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30rem;
  position: relative;
}

.form {
  width: 30rem;
}

.background {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.halfColumn {

  @media (min-width: 1024px) {
    max-width: 50%;
  }
}