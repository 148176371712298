@import "~component-library/dist/styles/constants.scss";

.numberLarge {
  font-size: 3.2rem;
  padding-right: 0.5rem;
  color: $boomerangBlue;
  font-weight: bold;
}

.number {
  font-size: 2.4rem;
  padding-right: 0.5rem;
  color: $boomerangBlue;
  font-weight: bold;

  @media (max-width: 1150px) {
    display: block;
  }
}

.activeProject{
  @media (max-width: 1150px) {
    width: 100%;
  }
}

.header {
  color: $gray02;
}