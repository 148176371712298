@import "~component-library/dist/styles/constants.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalHeader {
  padding: 1.6rem 2.4rem;
  background: $white;
  border-bottom: 1px solid #F1F0F0;
}

.modalBody {
  padding: 1.6rem;
  background: $white;
}
.modalContent {
  background: $white;
  border-radius: 0.5rem;
  border-top: 7px solid $boomerangBlue;
  max-width: 65rem;
}