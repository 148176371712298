@import "~component-library/dist/styles/constants.scss";

.formContainer {
  width: 100%;
  margin-bottom: 3rem;
}

.infoContainer {
  background-color: $lightGray;

  @media(min-width: 76.8rem) {
    margin-left: 3rem;
  }
}