@import "~component-library/dist/styles/constants.scss";

.header {
  background-color: $gray08;
  border-radius: 0.5rem;
}

.open {
  .header {
    border-radius: 0.5rem0.5rem 0 0;
  }
}

.description {
  background-color: $gray09;
  border-radius:  0 0 0.5rem 0.5rem;
}

.messageBlock {
  background-color: $gray08;
  border-radius: 0.5rem;
  display: flex;
  padding: 2.5rem 0;
  min-height: 45rem;
  justify-content: center;
  align-items: center;
  .message {
    text-align: center;
  }
}