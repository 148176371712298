@import "~component-library/dist/styles/constants.scss";

.categories {
  display: flex;
  flex-wrap: wrap;
}
.category {
  background-color: #a7a7a7;
  border-radius: 2rem;
  font-size: 1rem;
  font-weight: 600;
  color: $white;
  padding: 0.2rem 0.8rem;
  margin: 0.5rem 0.8rem 0.5rem 0;
}

.new {
  background-color:#7DDB2D;
  color: $white;
  font-size: 1.2rem;
  border-radius: 2rem;
  margin-right: 1rem;
  padding: 0.1rem 0.8rem;
}