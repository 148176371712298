@import "~component-library/dist/styles/constants.scss";

.projectInformation {
  margin: 0.5rem 0 1rem;
  padding: 1rem 0.5rem;
  background-color: $lightGray;
  border-radius: 0.5rem;
}
.milestones {
  table {
    width: 100%;
  }
  .milestone {
    margin: 0.5rem 0 1rem;
    background-color: $lightGray;
    border-radius: 1rem;
  }

  .milestone > td {
    color: #717171;
    padding: 0.3rem 1rem;
  }
  .milestone > td:first-of-type {
    border-radius: 0.5rem 0 0 0.5rem;
  }
  .milestone > td:last-of-type {
    border-radius: 0 0.5rem 0.5rem 0;
  }
}

.ownerBidNumber {
  color: $gray03;
  font-size: 1.4rem;
}

.followProject {
  color: $gray03;
  font-size: 1.4rem;
  display: flex;
  align-content: center;
  align-self: center;
}

.linkBadge{
  display: flex;
  align-items: center;
  background-color: #cde4f5;
  padding: 0.1rem 1rem;
  margin-top: 1rem;
  border-radius: 2.5rem;
  font-size: 1.2rem;
  font-weight: bold;
}

.backLink {
  display: flex;
  align-items: center;
}

.ellipsis {
  font-weight: bold;
  font-size: 2.6rem;
  line-height: 1.4rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
}

.quotesTitle {
  font-size: 1.8rem;
  font-weight: bold;
  color: $gray02;
}
.quoteFile {
  border: 2px solid $gray05;
  border-radius: 5px;
  display: inline-block;
  padding: 0.5rem 1.5rem;
}
.quotesText {
  font-weight: bold;
  padding-left: 1rem;
}

.sidebarInput {
  background-color: $lightGray;
  padding: 0.7rem 1rem;
  font-size: 1.6rem;

  color: $gray02;
  width: 100%;
  border: none;
}

.sidebarTitle {
  font-size: 1.6rem;
  color: $gray02;
}

.yourQuote {
  padding-bottom: 1.2rem;
  border-bottom: 1px solid $lightGray;
}

.sidebar {
  border-left: 1px solid $lightGray;
}