@import "~component-library/dist/styles/constants.scss";

.banner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 13.5rem;
  color: $white;
  background-color: $boomerangBlue;
  overflow: hidden;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.bannerText {
  z-index: 1;
}