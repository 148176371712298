@import "~component-library/dist/styles/constants.scss";

.statusWrapper {
  color: $gray02;
  padding: 0.3rem 0.7rem;
  background-color: $gray06;
  border-radius: 0.4rem;
}
.dot {
  align-items: center;
  display: flex;
  background-color: color;
  border-radius: 0.3rem;
  content: "";
  margin-right: 0.6rem;
  height: 0.6rem;
  width: 0.6rem;
  flex-shrink: 0;
}