@import "~component-library/dist/styles/constants.scss";

.app-header {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 65rem;
  height: 5rem;
  padding: 0 4rem 0 1.5rem;
  background-color: $gray08;
}

.app-menu {
  position: relative;
}

.menu-content {
  position: absolute;
  background-color: $white;
  z-index: 999;
}

.public {
  padding: 0 2rem;
  height: 5rem;
  background-color: $gray09;
}

.private {
  background-color: $gray08;
}

.actionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0.8rem;
  height: 3.2rem;
  width: 3.2rem;
  margin: 0 1.5rem 0 0;
  background-color: transparent;
}

.actionIcon {
  color: $white;
}