@import "~component-library/dist/styles/constants.scss";

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.2rem;
  min-width: 3.2rem;
  margin-right: 1rem;
  height: 3.2rem;
  border-radius: 1.6rem;
  background-color: $blue05;
}

.infoWrapper {
  margin: 0 auto;
  padding: 2.5rem;

  @media(min-width: 768px) {
    padding: 5rem;
  }
}

.infoList {
  max-width: 60rem;
  margin: 0 auto;
}