@import "~component-library/dist/styles/constants.scss";

.notice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
  background-color: #E5F8D5;
  padding: 0.7rem 1rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  
  a {
    color: $gray02;
    text-decoration: none;
  }
}