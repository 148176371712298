@import "~component-library/dist/styles/constants.scss";

.ctaContainer {
  max-width: 60rem;
  margin: 0 auto;
  width: 100%;

  @media (min-width: 76.8rem) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.ctaBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 50%;
  margin: 1.5rem;
  border-radius: 0.4rem;
  padding: 2rem;
  text-align: center;
  color: #fff;
  font-weight: bold;

  @media (min-width: 768px) {
    margin: 0 1.5rem;
  }
}