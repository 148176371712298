@import "~component-library/dist/styles/constants.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "~component-library/dist/styles/forms.scss";
@import "~component-library/dist/styles/siteNav.scss";
@import "~component-library/dist/styles/quotes.scss";
@import "~component-library/dist/styles/sortableTable.scss";

html {
  font-size: 62.5%;
}

body {
  color: $dark-text;
  font-size: 1.6rem;
  font-family: "Source Sans Pro", sans-serif;
}

.landing-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 4.5rem);
}

.max-text-wrapper {
  max-width: 50rem;
}

.sm {
  font-size: $sm;
}

.thirteen {
  font-size: $thirteen;
}

.sm-med {
  font-size: $sm-med;
}

.med {
  font-size: $med;
}

.lg {
  font-size: $lg;
}

.xl {
  font-size: $xl;
}

.xxl {
  font-size: $xxl;
}

.xxxl {
  font-size: $xxl;
}

.btn-tertiary {
  color: $white;

  &:disabled,
  &:hover,
  &:focus {
    color: $white !important;
  }
}

.font-weight-bold {
  font-weight: 600;
}

.primary-input {
  padding: 1rem 3.2rem;
  border: 0;
  background-color: $blue06;
  border-radius: 0.4rem;
}

.vendor-application {
  .work-categories {
    .form-check-input {
      opacity: 0;
      position: absolute;
      margin: 0;
      + .form-check-label {
        line-height: 2rem;
        display: flex;
        border: 0.2rem solid $gray05;
        padding: 0.8rem 1.8rem;
        border-radius: 2rem;

        &:hover {
          cursor: pointer;
          border-color: $boomerangBlue;
        }

        &::before {
          display: none;
        }
      }

      &:checked + .form-check-label {
        border: 2px solid $boomerangBlue;
        color: $white;
        background: $boomerangBlue;
      }

      &:checked + .form-check-label {
        + svg {
          display: none;
        }
      }

      &:focus {
        + .form-check-label {
          border-color: #4fafff;
          outline: 0;
          box-shadow: 0 0 0 .32 rem rgba(0, 113, 206, 0.25);
        }
      }
    }
  }

  .state-dropdown {
    .form-control-select-required {
      
      > div > div {
        border: 2px solid #ced4da;
        height: 48px;

        + div {
          height: auto;
        }
      }
    }
  }
}

.vendor-avatar {
  border: none;
  background: none;
}

.siteHeader {
  min-height: 4.8rem;
  background-color: $navy;
  width: 100%;
  display: flex;
}

.siteContent {
  flex-grow: 1;
  margin-left: 1.6rem;
  transition: all .25s ease-in-out;
}

.pageHeader {
  min-height: 4rem;
  color: $nearBlack;
  background-color: $gray08;
  display: flex;
  padding: 0 4rem 0 2.4rem;
  align-items: center;
  justify-content: space-between;
  h1 {
    font-weight: 600;
  }
  h2 {
    margin-bottom: 0;
    font-size: 1.6rem;
    color:#193851;
    font-weight: 400;
  }
}

.btn-orange {
  background-color: $yellowOrange;
  color: $white
}

.contact-pill {
  background-color: #0071ce10;
  border-radius: 2.5rem;
  padding: 0.3rem 0.7rem;
  margin: 0.5rem 0 0.5rem;
  width: fit-content;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.archived {
    color: $gray03;
    background-color: $gray06;
  }
}

.large-form-section {
  border-bottom: 1px solid $gray06;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.circle-file-dropzone .dropzone {
  border-radius: 100%;
  height: 7.2rem;
  width: 7.2rem;
  padding: 1.2rem;
  border: 1px dashed #797c84;
  background-color: $gray08;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  p {
    font-size: 1.3rem;
    color: #8f8f8f;
  }
}

.circle-file-dropzone .dropzone:hover {
  background-color: #797c84;
  border: 1px dashed #797c84;

  p {
    color: #fff;
  }
}


.modal-content {
  border-top: 0.8rem solid $boomerangBlue;
}

.modal-header {
  h4 {
    font-size: 1.6rem;
  }
}

.modal-close {
  background-color: #f0f0f0;
  border: #f0f0f0;
  padding: 1rem;
  border-radius: 0.8rem;
  &:hover {
    background-color: #797c84;
  }
  &:active {
    background-color: #f0f0f0 !important;
  }
  &:focus {
    background-color: #f0f0f0;
  }
}

.blue-hover {
  &:hover {
    color: $boomerangBlue;
  }
}

.addenda-count {
  background-color: $yellowOrange;
  color: #fff;
  display: block;
  height: 1.6rem;
  border-radius: 0.8rem;
  padding: 0 0.3rem;
  min-width: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: normal;
  margin-left: 0.8rem;
}

.ag-row-group {
  font-size: 1.6rem !important;
}

.calendarWrap {
  .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
    display: none;
  }
}

.react-datepicker {
  font-size: 1.3rem !important;
}

.react-datepicker__current-month {
  font-size: 1.5rem !important;
  line-height: 2;
}

.react-datepicker__header {
  padding-top: 0.6rem !important;
}

.react-datepicker__navigation {
  top: 1.3rem !important;
}

.react-datepicker__day-name, .react-datepicker__day {
  margin: 0.5rem !important;
}