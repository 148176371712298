@import "~component-library/dist/styles/constants.scss";

.section {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid $gray08;
}

.supplierWrapper {
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
}

.metaWrapper {
  display: flex;
}

.status {
  width: 25rem;

  @media (min-width: 768px) {
    padding: 2.5rem 0 2.5rem 2.5rem;
    margin-left: 2.5rem;
    border-left: 1px solid $gray08;
  }
}

.supplier {
  @media (min-width: 768px) {
    padding: 2.5rem 2.5rem 2.5rem 0;
  }
}

.controls {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;

  .link {
    margin: 0 0.4rem;
  }
}

.quoteItemsTable {
  color: $gray03;

  th {
    text-align: left;
    text-transform: uppercase;
    font-weight: normal;
    color: $gray03;
    padding: 0.5rem 0.5rem;
    font-size: 1.4rem;
    border: none !important;
  }

  td {
    padding: 0.5rem 0.5rem;
    border: none;
    font-size: 1.6rem;
  }

  input {
    color: $gray02;
  }

  .quantity {
    font-size: 1.4rem;
    display: inline-block;
    min-width: 5rem;
  }
}

.nameCell {
  font-weight: bold;
  color: $gray02;
}

.tableInput {
  width: 6.5rem;
  border-radius: 0.4rem;
  border: 1px solid $gray05;
  background-color: $white;
  padding: 0 0.8rem;
}

.halfCell {
  width: 50%;
}

.unitOfMeasure {
  display: inline-block;
  margin-left: 1.5rem;
  color: $gray03;
}

.contact {
  width: 100%;
  margin-bottom: 1rem;
  @media (min-width: 45rem) {
    width: 50%;
    margin-bottom: 0;
    margin-right: 1.4rem;
    max-width: 19.3rem;
    &:last-child {
      margin-right: 0;
    }
  }
}

.contacts {
  padding: 1.8rem 0;
  @media (min-width: 450px) {
    display: flex;
  }
  @media (min-width: 800px) {
    width: 60%;
  }
}

.historyItem {
  display: flex;
  justify-content: space-between;
  border-radius: 0.4rem;
  margin-bottom: 1rem;
  padding: 1rem 1.2rem;
  background-color: $gray08;
}

.smallWrapper {
  width: 7.5rem;
}

.mediumWrapper {
  width: 10rem;
}

.largeWrapper {
  width: 13.5rem;
}

.terms {
  input {
    border-radius: 0.4rem;
    border: 1px solid $gray04;
    color: $gray03;
    background-color: $white;
    padding: 0.3rem 0.8rem;
    width: 100%;
  }
}