@import "~component-library/dist/styles/constants.scss";

.contact {
  background-color: $gray09;
  padding: 1.6rem;
  border-radius: 1.2rem;
  margin-right: 2rem;
  align-self: start;
  margin-bottom: 2rem;

  .status {
    color: $teal;
    font-size: 1.3rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  .job {
    line-height: 1rem;
    font-size: 1.1rem;
    font-weight: lighter;
    margin-bottom: 0.5rem;
  }
  .name {
    font-size: 1.6rem;
  }
  .email {
    color: $boomerangBlue;
    font-size: 1.1rem;
    background-color: RGBA(0, 113, 206, 0.1);
    border-radius: 2.5rem;
    padding: 0.2rem 0.5rem;
    display: inline-block;
    font-weight: bold;
  }
  .phone {
    color: $boomerangBlue;
    font-size: 1.1rem;
    background-color: RGBA(0, 113, 206, 0.1);
    border-radius: 2.5rem;
    padding: 0.2rem 0.5rem;
    display: inline-block;
    font-weight: bold;
  }
}
.header {
  font-size: 2rem;
  color: $gray02;
}
.content {
  font-size: 1.6rem;
  color: $gray02;
}
.contactButton {
  background-color: $gray08;
  border: 2px dashed $gray05;
  color: $gray03;
  font-size: 1.2rem;
  align-items: center;
  text-align: center;
  display: flex;
  height: 7.5rem;
  width: 7.5rem;
  border-radius: 50%;
}
