@import "~component-library/dist/styles/constants.scss";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.quote {
  background-color: $teal;
  color: $white;
  border-radius: 1.5rem;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  .header {
    a {
      color: $white; 
      text-transform: uppercase;
    }
  }
}

.noticeBadge {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #7DDB2D;
  padding: 0.5rem 1.5rem;
  border-radius: 5rem;
  color: $white;
  margin-bottom: 1rem;
  
  a {
    color: $white;
    text-transform: uppercase;
  }
  .number{
    font-size: 1.6rem;
    padding-left: 0.5rem;
    font-weight: bold;
  }
}

.update {
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: $gray09;
  display: flex;
  align-items: center;
  flex: 1;
  gap: 1rem;
  .content {
    padding: 0 0.5rem;
    .userName {
      font-weight: bold;
    }
    .submittalName {
      font-weight: bold;
    }
  }
}