@import "~component-library/dist/styles/constants.scss";

.contentWrapper {
  margin: 0 auto;
  max-width: 54.5rem;
}

.formInput {
  background-color: $blue06;
}

.contactBlock {
  background-color: $gray06;
}