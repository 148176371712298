@import "~component-library/dist/styles/constants.scss";

.logo {
  margin-bottom: 4.4rem;
}

.submit {
  align-self: center;
  margin-top: 0.8rem;
  color: #fff;
}

.footer {
  display: flex;
  justify-content: center;
}

.form-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 51.2rem;
  position: relative;
}

.form {
  width: 100%;
  max-width: 30rem;
}

.background {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.halfColumn {

  @media (min-width: 1024px) {
    max-width: 50%;
  }
}