@import "~component-library/dist/styles/constants.scss";

.pendingQuotes {
  th {
    text-align: left;
    text-transform: uppercase;
    font-weight: normal;
    color: $gray03;
    border: none !important;
    padding: 0.5rem 0.5rem;
    font-size: 1.4rem;
  }
  td {
    color: $gray03;
    padding: 0.5rem 0.5rem;
    border: none;
    vertical-align: middle !important;
    margin: 0 !important;
    font-size: 1.6rem;
  }
  .projectName {
    font-size: 1.6rem;
    font-weight: bold;
    color: $gray02;
  }
}